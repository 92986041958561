// import logo from './logo.svg';
import React from 'react';
import './App.scss';
import {HashRouter as Router, Route,NavLink,Link,withRouter} from 'react-router-dom';
// import { withRouter } from 'react-router-dom'
// import { Image } from '@components'
import { imgUrl } from './config/index';
import routes from './config/routes';
import { Select,Switch,Drawer  } from 'antd';
import { AlignRightOutlined } from '@ant-design/icons';

class App extends React.Component {
// function App() {
  // const [language, setlanguage] = useState("CN");

  // const handleChange = (value) =>{
  //   console.log(`selected ${value}`);
  // }
  
  state={
    language:"CN",
    str:11,
    Drawerac:false
  }
  componentWillMount(){
    window.language = 'CN'
    
  }
  SwitchonChange = (str) =>{
    // let l = language
    // str?setlanguage("CN"):setlanguage("EN")
    // str?this.setState({language:"CN"}):this.setState({language:"EN"})
    // this.forceUpdate();
    // console.log(str)
    // str?window.language = "CN":window.language = "EN"
    window.language = str
    this.setState({language:window.language})
    // this.render();
  }
  render(){

  const { str,language,Drawerac } = this.state
    console.log(Drawerac,"Drawerac")
    return (
      <Router>
        <div className="App" id="App">

          <header className="header">
            {/* 电脑 */}
            <div className="info container hidden-xs hidden-sm">
              <div className="left" >
                <div className="item">
                  <img src={imgUrl+"anzy_logo_w.png"} className="img" />
                  {/* {language != "EN"?"安之健康":"Anzy Health"} */}
                  {language != "EN"?"安之健康科技（广东）有限公司":"Anzy Health Technology (Guangdong) Co., Ltd. "}
                </div>
                {/* <div className="item">{language != "EN"?"安之健康科技（广东）有限公司":"Anzy Health Technology (Guangdong) Co., Ltd. "}</div> */}
              
              </div>
              <div className="right">
                <div className="right_item">
                  {
                    routes&&routes.length>0?routes.map((l,i)=>
                    l.exact?null:
                      <NavLink to={l.path} key={i} activeClassName="itemac" className="link item" >{language != "EN"?l.name:l.name1}</NavLink>
                    ):null
                  }

                </div>
                <div className="switch">
                  <Select defaultValue="中文" style={{ width: 100 }} onChange={this.SwitchonChange}>
                    <Select.Option value="CN">中文</Select.Option>
                    <Select.Option value="EN">English</Select.Option>
                  </Select>
                  {/* <Switch checkedChildren="中文" unCheckedChildren="English" defaultChecked={true} style={{width:70}} onChange={this.SwitchonChange}/> */}
                </div>
                
              </div>
            </div>
            {/* 手机 */}
            <div className="infos container hidden-md hidden-lg">
              <div className="left" >
                <div className="item">
                  <img src={imgUrl+"anzy_logo_w.png"} className="img" />
                  {language != "EN"?"安之健康":"Anzy Health"}
                </div>
              </div>
              <div className="right" >
                <AlignRightOutlined style={{fontSize:24}} onClick={()=>this.setState({Drawerac:true})}/>
                <Drawer
                  title={language != "EN"?"安之健康":"Anzy Health"}
                  placement="top"
                  closable={true}
                  onClose={()=>this.setState({Drawerac:false})}
                  visible={Drawerac}
                  height={"auto"}
                  // style={{background:"red"}}
                >
                  <div className="right_items">
                    <div className="switch" style={{textAlign:"center"}}>
                      <Select defaultValue="中文" style={{ width: 100 }} onChange={this.SwitchonChange}>
                        <Select.Option value="CN">中文</Select.Option>
                        <Select.Option value="EN">English</Select.Option>
                      </Select>
                    </div>
                    {
                      routes&&routes.length>0?routes.map((l,i)=>
                      l.exact?null:
                        <NavLink to={l.path} key={i} activeClassName="itemac" className="item" >
                          <div className="item" style={{textAlign:"center",padding:15,color:"#000"}} onClick={()=>this.setState({Drawerac:false})} >{language != "EN"?l.name:l.name1}</div>
                        </NavLink>
                      ):null
                    }

                  </div>
                </Drawer>
              </div>
            </div>



          </header>
          <div className="Info_box">
            {/* <Route exact path="/" component={routes[0].component}  /> */}
          {
            routes.map((l,i)=>
            l.exact?
              <Route exact key={i} path="/" component={l.component} lang={111}  />
              :<Route key={i} path={l.path} component={l.component} />
            )
          }
          </div>
          <footer className="Footer">
            <div>版权所有 安之健康科技（广东）有限公司</div>
            <div>Copyright@anzyhealth.com All right reserved 粤ICP备2021007117号</div>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;


export const introduce = {
  CN:"安之健康技术（广东）有限公司经营范围： 中医健康监测类软件开发、硬件设计、信息系统集成、工程设计、技术咨询服务、售后维护支持等、进出口业务；保健食品生产及销售；"+
     "医疗器械的生产 , 批发 , 零售（凭许可证经营）；健康管理（须经审批的诊疗活动除外）、健康咨询（须经审批的诊疗活动除外） 。",
  EN:"Anzy Health Technology (Guangdong) Co., Ltd.: Which is a company which focus on human health technology.  Copmany business scope:  software development, "+
     "hardware design, information system integration,engineering design, technical consulting service, after-sales maintenance support,"+
     "import and export business of traditional Chinese medicine health monitoring; production and sales of health food; production and wholesale of medical devices,"+
     "Retail (operate with license); health management (except for approved diagnosis and treatment activities), health consultation (except for approved diagnosis and treatment activities)."
}

export const Address = {
  address1:{
    CN:"深圳市宝安区银田创业园36栋商业中心609室",
    EN:"room 609, building 36, Yintian business center,, Bao'an District, Shenzhen"
  },
  address2:{
    CN:"广东省惠州市仲恺高新区惠风七路7号805室",
    EN:"Room 805, No.7, Road 7th Huifeng, Zhongkai high tech Zone, Huizhou City, Guangdong Province"
  },
  // email:{
  //   CN:"Anzytech@163.com",
  //   EN:"Anzytech@163.com"
  // }
  email:"Anzytech@163.com",
  phone:"13825230086"

}

export const Productarr = [
  {
    id:1,
    name:{
      CN:"智能理疗设备",
      EN:"Intelligent physiotherapy equipment"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:1_1,
        name:{
          CN:"刮痧仪",
          EN:"TCM (Traditional Chinese Medicine)scraping instrument"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_1 (1).png"}, EN:{text:"",src:"product/image1_1 (1).png"}  },
          {  CN:{text:"",src:"product/image1_1 (2).png"}, EN:{text:"",src:"product/image1_1 (2).png"}  },
          {  CN:{text:"",src:"product/image1_1 (3).png"}, EN:{text:"",src:"product/image1_1 (3).png"}  },
          {  CN:{text:"",src:"product/image1_1 (4).png"}, EN:{text:"",src:"product/image1_1 (4).png"}  },
          {  CN:{text:"",src:"product/image1_1 (5).png"}, EN:{text:"",src:"product/image1_1 (5).png"}  },
          {  CN:{text:"",src:"product/image1_1 (6).png"}, EN:{text:"",src:"product/image1_1 (6).png"}  },
          {  CN:{text:"",src:"product/image1_1 (7).png"}, EN:{text:"",src:"product/image1_1 (7).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_1 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (7).png" },
        ],
      },
      {
        id:1_2,
        name:{
          CN:"经络笔",
          EN:"TCM Meridian Electronic pen"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_2 (1).png"}, EN:{text:"",src:"product/image1_2 (1).png"}  },
          {  CN:{text:"",src:"product/image1_2 (2).png"}, EN:{text:"",src:"product/image1_2 (2).png"}  },
          {  CN:{text:"",src:"product/image1_2 (3).png"}, EN:{text:"",src:"product/image1_2 (3).png"}  },
          {  CN:{text:"",src:"product/image1_2 (4).png"}, EN:{text:"",src:"product/image1_2 (4).png"}  },
          {  CN:{text:"",src:"product/image1_2 (5).png"}, EN:{text:"",src:"product/image1_2 (5).png"}  },
          {  CN:{text:"",src:"product/image1_2 (6).png"}, EN:{text:"",src:"product/image1_2 (6).png"}  },
          {  CN:{text:"",src:"product/image1_2 (7).png"}, EN:{text:"",src:"product/image1_2 (7).png"}  },
          {  CN:{text:"",src:"product/image1_2 (8).png"}, EN:{text:"",src:"product/image1_2 (8).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_2 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (8).png" },
        ],
      },
      {
        id:1_3,
        name:{
          CN:"颈椎按摩仪",
          EN:"Cervical vertebra massage instrument"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_3 (1).png"}, EN:{text:"",src:"product/image1_3 (1).png"}  },
          {  CN:{text:"",src:"product/image1_3 (2).png"}, EN:{text:"",src:"product/image1_3 (2).png"}  },
          {  CN:{text:"",src:"product/image1_3 (3).png"}, EN:{text:"",src:"product/image1_3 (3).png"}  },
          {  CN:{text:"",src:"product/image1_3 (4).png"}, EN:{text:"",src:"product/image1_3 (4).png"}  },
          {  CN:{text:"",src:"product/image1_3 (5).png"}, EN:{text:"",src:"product/image1_3 (5).png"}  },
          {  CN:{text:"",src:"product/image1_3 (6).png"}, EN:{text:"",src:"product/image1_3 (6).png"}  },
          {  CN:{text:"",src:"product/image1_3 (7).png"}, EN:{text:"",src:"product/image1_3 (7).png"}  },
          {  CN:{text:"",src:"product/image1_3 (8).png"}, EN:{text:"",src:"product/image1_3 (8).png"}  },
          {  CN:{text:"",src:"product/image1_3 (9).png"}, EN:{text:"",src:"product/image1_3 (9).png"}  },
          {  CN:{text:"",src:"product/image1_3 (10).png"}, EN:{text:"",src:"product/image1_3 (10).png"}  },
          {  CN:{text:"",src:"product/image1_3 (11).png"}, EN:{text:"",src:"product/image1_3 (11).png"}  },
          {  CN:{text:"",src:"product/image1_3 (12).png"}, EN:{text:"",src:"product/image1_3 (12).png"}  },
          
          // { text:{CN:"",EN:""},src:"product/image1_3 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (8).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (9).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (10).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (11).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (12).png" },
        ],
        video:[
          { text:{CN:"",EN:""},src:"product/video1_3 (1).mp4" },
        ]
      },
      {
        id:1_4,
        name:{
          CN:"护眼仪",
          EN:"Eye protector"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_4 (1).png"}, EN:{text:"",src:"product/image1_4 (1).png"}  },
          {  CN:{text:"",src:"product/image1_4 (2).png"}, EN:{text:"",src:"product/image1_4 (2).png"}  },
          {  CN:{text:"",src:"product/image1_4 (3).png"}, EN:{text:"",src:"product/image1_4 (3).png"}  },
          {  CN:{text:"",src:"product/image1_4 (4).png"}, EN:{text:"",src:"product/image1_4 (4).png"}  },
          {  CN:{text:"",src:"product/image1_4 (5).png"}, EN:{text:"",src:"product/image1_4 (5).png"}  },
          {  CN:{text:"",src:"product/image1_4 (6).png"}, EN:{text:"",src:"product/image1_4 (6).png"}  },
          {  CN:{text:"",src:"product/image1_4 (7).png"}, EN:{text:"",src:"product/image1_4 (7).png"}  },
          {  CN:{text:"",src:"product/image1_4 (8).png"}, EN:{text:"",src:"product/image1_4 (8).png"}  },
          
          // { text:{CN:"",EN:""},src:"product/image1_4 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (8).png" },
        ],
      },
      {
        id:1_5,
        name:{
          CN:"Mini筋膜枪",
          EN:"Mini fascia gun"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_5 (1).png"}, EN:{text:"",src:"product/image1_5 (1).png"}  },
          {  CN:{text:"",src:"product/image1_5 (2).png"}, EN:{text:"",src:"product/image1_5 (2).png"}  },
          {  CN:{text:"",src:"product/image1_5 (3).png"}, EN:{text:"",src:"product/image1_5 (3).png"}  },
          {  CN:{text:"",src:"product/image1_5 (4).png"}, EN:{text:"",src:"product/image1_5 (4).png"}  },
          {  CN:{text:"",src:"product/image1_5 (5).png"}, EN:{text:"",src:"product/image1_5 (5).png"}  },
          {  CN:{text:"",src:"product/image1_5 (6).png"}, EN:{text:"",src:"product/image1_5 (6).png"}  },
          {  CN:{text:"",src:"product/image1_5 (7).png"}, EN:{text:"",src:"product/image1_5 (7).png"}  },
          {  CN:{text:"",src:"product/image1_5 (8).png"}, EN:{text:"",src:"product/image1_5 (8).png"}  },
          {  CN:{text:"",src:"product/image1_5 (9).png"}, EN:{text:"",src:"product/image1_5 (9).png"}  },
          {  CN:{text:"",src:"product/image1_5 (10).png"}, EN:{text:"",src:"product/image1_5 (10).png"}  },
          {  CN:{text:"",src:"product/image1_5 (11).png"}, EN:{text:"",src:"product/image1_5 (11).png"}  },
          {  CN:{text:"",src:"product/image1_5 (12).png"}, EN:{text:"",src:"product/image1_5 (12).png"}  },
          {  CN:{text:"",src:"product/image1_5 (13).png"}, EN:{text:"",src:"product/image1_5 (13).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_5 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (8).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (9).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (10).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (11).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (12).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (13).png" },
        ],
        video:[
          { text:{CN:"",EN:""},src:"product/video1_5 (1).mp4" },
        ]
      }
    ]
  },
  {
    id:2,
    name:{
      CN:"智能健康穿戴",
      EN:"Intelligent health wear"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:2_1,
        name:{      
          CN:"智能儿童手表A81",
          EN:"Smart children's Watch"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image2_1 (1).jpg"}, EN:{text:"",src:"product/image2_1e (1).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (2).jpg"}, EN:{text:"",src:"product/image2_1e (2).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (3).jpg"}, EN:{text:"",src:"product/image2_1e (3).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (4).jpg"}, EN:{text:"",src:"product/image2_1e (4).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (5).jpg"}, EN:{text:"",src:"product/image2_1e (5).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (6).jpg"}, EN:{text:"",src:"product/image2_1e (6).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (7).jpg"}, EN:{text:"",src:"product/image2_1e (7).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (8).jpg"}, EN:{text:"",src:"product/image2_1e (8).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (9).jpg"}, EN:{text:"",src:"product/image2_1e (9).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (10).jpg"}, EN:{text:"",src:"product/image2_1e (10).jpg"}  },
          {  CN:{text:"",src:"product/image2_1 (11).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image2_1 (12).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image2_1 (13).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:2_2,
        name:{      
          CN:"健康心电手环C18",
          EN:"Healthy ECG Bracelet"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image2_2 (1).jpg"}, EN:{text:"",src:"product/image2_2 (1).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (2).jpg"}, EN:{text:"",src:"product/image2_2 (2).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (3).jpg"}, EN:{text:"",src:"product/image2_2 (3).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (4).jpg"}, EN:{text:"",src:"product/image2_2 (4).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (5).jpg"}, EN:{text:"",src:"product/image2_2 (5).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (6).jpg"}, EN:{text:"",src:"product/image2_2 (6).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (7).jpg"}, EN:{text:"",src:"product/image2_2 (7).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (8).jpg"}, EN:{text:"",src:"product/image2_2 (8).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (9).jpg"}, EN:{text:"",src:"product/image2_2 (9).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (10).jpg"}, EN:{text:"",src:"product/image2_2 (10).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (11).jpg"}, EN:{text:"",src:"product/image2_2 (11).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (12).jpg"}, EN:{text:"",src:"product/image2_2 (12).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (13).jpg"}, EN:{text:"",src:"product/image2_2 (13).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (14).jpg"}, EN:{text:"",src:"product/image2_2 (14).jpg"}  },
          {  CN:{text:"",src:"product/image2_2 (15).jpg"}, EN:{text:"",src:"product/image2_2 (15).jpg"}  },
          {  CN:{text:"",src:""}, EN:{text:"",src:"product/image2_2 (16).jpg"}  },
          {  CN:{text:"",src:""}, EN:{text:"",src:"product/image2_2 (17).jpg"}  },
        ],
      },
      {
        id:2_3,
        name:{      
          CN:"智能健康老年手环I9",
          EN:"Smart Healthy Watch for Elderly"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image2_3 (1).jpg"}, EN:{text:"",src:"product/image2_3 (1).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (2).jpg"}, EN:{text:"",src:"product/image2_3 (2).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (3).jpg"}, EN:{text:"",src:"product/image2_3 (3).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (4).jpg"}, EN:{text:"",src:"product/image2_3 (4).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (5).jpg"}, EN:{text:"",src:"product/image2_3 (5).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (6).jpg"}, EN:{text:"",src:"product/image2_3 (6).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (7).jpg"}, EN:{text:"",src:"product/image2_3 (7).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (8).jpg"}, EN:{text:"",src:"product/image2_3 (8).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (9).jpg"}, EN:{text:"",src:"product/image2_3 (9).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (10).jpg"}, EN:{text:"",src:"product/image2_3 (10).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (11).jpg"}, EN:{text:"",src:"product/image2_3 (11).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (12).jpg"}, EN:{text:"",src:"product/image2_3 (12).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (13).jpg"}, EN:{text:"",src:"product/image2_3 (13).jpg"}  },
          {  CN:{text:"",src:"product/image2_3 (14).jpg"}, EN:{text:"",src:"product/image2_3 (14).jpg"}  },
        ],
      },
      {
        id:2_4,
        name:{      
          CN:"智能健康老年手环S5",
          EN:"Smart Healthy Bracelet for Elderly"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image2_4 (1).jpg"}, EN:{text:"",src:"product/image2_4 (1).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (2).jpg"}, EN:{text:"",src:"product/image2_4 (2).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (3).jpg"}, EN:{text:"",src:"product/image2_4 (3).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (4).jpg"}, EN:{text:"",src:"product/image2_4 (4).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (5).jpg"}, EN:{text:"",src:"product/image2_4 (5).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (6).jpg"}, EN:{text:"",src:"product/image2_4 (6).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (7).jpg"}, EN:{text:"",src:"product/image2_4 (7).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (8).jpg"}, EN:{text:"",src:"product/image2_4 (8).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (9).jpg"}, EN:{text:"",src:"product/image2_4 (9).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (0).jpg"}, EN:{text:"",src:"product/image2_4 (10).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (11).jpg"}, EN:{text:"",src:"product/image2_4 (11).jpg"}  },
          {  CN:{text:"",src:"product/image2_4 (12).jpg"}, EN:{text:"",src:"product/image2_4 (12).jpg"}  },
        ],
      },
      {
        id:2_5,
        name:{      
          CN:"智能健康监测手表 V6",
          EN:"Health monitoring smart Watch"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image2_5 (1).png"}, EN:{text:"",src:"product/image2_5 (1).png"}  },
          {  CN:{text:"",src:"product/image2_5 (2).png"}, EN:{text:"",src:"product/image2_5 (2).png"}  },
          {  CN:{text:"",src:"product/image2_5 (3).png"}, EN:{text:"",src:"product/image2_5 (3).png"}  },
          {  CN:{text:"",src:"product/image2_5 (4).png"}, EN:{text:"",src:"product/image2_5 (4).png"}  },
          {  CN:{text:"",src:"product/image2_5 (5).png"}, EN:{text:"",src:"product/image2_5 (5).png"}  },
          {  CN:{text:"",src:"product/image2_5 (6).png"}, EN:{text:"",src:"product/image2_5 (6).png"}  },
          {  CN:{text:"",src:"product/image2_5 (7).png"}, EN:{text:"",src:"product/image2_5 (7).png"}  },
          {  CN:{text:"",src:"product/image2_5 (8).png"}, EN:{text:"",src:"product/image2_5 (8).png"}  },
          {  CN:{text:"",src:"product/image2_5 (9).png"}, EN:{text:"",src:"product/image2_5 (9).png"}  },
          {  CN:{text:"",src:"product/image2_5 (10).png"}, EN:{text:"",src:"product/image2_5 (10).png"}  },
          {  CN:{text:"",src:"product/image2_5 (11).png"}, EN:{text:"",src:"product/image2_5 (11).png"}  },
          {  CN:{text:"",src:"product/image2_5 (12).png"}, EN:{text:"",src:"product/image2_5 (12).png"}  },
          {  CN:{text:"",src:"product/image2_5 (13).png"}, EN:{text:"",src:"product/image2_5 (13).png"}  },
          {  CN:{text:"",src:"product/image2_5 (14).png"}, EN:{text:"",src:"product/image2_5 (14).png"}  },
          {  CN:{text:"",src:"product/image2_5 (15).png"}, EN:{text:"",src:"product/image2_5 (15).png"}  },
          {  CN:{text:"",src:"product/image2_5 (16).png"}, EN:{text:"",src:"product/image2_5 (16).png"}  },
          {  CN:{text:"",src:"product/image2_5 (17).png"}, EN:{text:"",src:"product/image2_5 (17).png"}  },
          {  CN:{text:"",src:"product/image2_5 (18).png"}, EN:{text:"",src:"product/image2_5 (18).png"}  },
          {  CN:{text:"",src:"product/image2_5 (19).png"}, EN:{text:"",src:"product/image2_5 (19).png"}  },
          {  CN:{text:"",src:"product/image2_5 (20).png"}, EN:{text:"",src:"product/image2_5 (20).png"}  },
          {  CN:{text:"",src:"product/image2_5 (21).png"}, EN:{text:"",src:"product/image2_5 (21).png"}  },
        ],
        video:[
          { text:{CN:"",EN:""},src:"product/video2_5 (1).mp4" },
        ]
      }
    ]
  },
  {
    id:3,
    name:{      
      CN:"定制健康方案",
      EN:"Customized health programme"
    },
    least:true,
    text:{
      CN:"",
      EN:""
    },
    products:[
      {  CN:{text:"",src:"product/image3 (1).png"}, EN:{text:"",src:"product/image3 (1).png"}  },
      {  CN:{text:"",src:"product/image3 (2).png"}, EN:{text:"",src:"product/image3 (2).png"}  },
      {  CN:{text:"",src:"product/image3 (3).png"}, EN:{text:"",src:"product/image3 (3).png"}  },
      {  CN:{text:"",src:"product/image3 (4).png"}, EN:{text:"",src:"product/image3 (4).png"}  },
      {  CN:{text:"",src:"product/image3 (5).png"}, EN:{text:"",src:"product/image3 (5).png"}  },
      {  CN:{text:"",src:"product/image3 (6).png"}, EN:{text:"",src:"product/image3 (6).png"}  },
      {  CN:{text:"",src:"product/image3 (7).png"}, EN:{text:"",src:"product/image3 (7).png"}  },
      {  CN:{text:"",src:"product/image3 (8).png"}, EN:{text:"",src:"product/image3 (8).png"}  },
      {  CN:{text:"",src:"product/image3 (9).png"}, EN:{text:"",src:"product/image3 (9).png"}  },

    ],
  },
  
]

// export const Plan = {
//   "系统方案：我司为各中医院及中医馆提供公众号和小程序管理系统方案，主要功能有：中医在线问诊"
// 一、常规功能模块
// 1、固定模块的回访对话框
// 2、回访提醒功能
// 3、预约支付
// 4、问诊完后，除了处方，还有调理建议和辅助产品推荐的购买，不用患者再退出页面进到商城
// 5、通过网上问诊、视频问诊、电话问诊、医疗知识推送
// 二、语音课程和视频培训模块                                                                                                                   
// 1、视频培训，公众号营销、做微服务、微推广，能够向培训的学员展示的一个很好的渠道，中医培训要想向外界展示自己的师资力量、主要课程；
// 2、老师答疑。微信能够实现的另一个非常重要的功能就是老师答疑，微信公众号基于自身的文字和语音功能，能够实现学员与老师的沟通。
// 3、微投票。微投票是一项很重要的功能，教育培训利用微投票能够做的事情也是比较多的，例如能够帮助培训机构投票评选优秀学员，优秀老师等等。
// 4、报名预约系统。这个系统也是很重要的，学员无需线下去学校报名预约，直接通过微信就能完成这些事情。
// 5、绩查询系统、会员管理系统、文章助手、线下活动、支付中心、全景展示等等。
// 三、产品展示和商城                                                                                                                                 
// 1、合作商家联盟、广告位，优惠活动展示
// 可以实现在线浏览产品、寻医问药、在线诊疗等。可以让节省排队时间，在满足病人需求的时候，同时也能带来更多有治疗需求的病人，进行医患互动、复诊提醒和复诊预约等服务。 
// 2、通过中医馆公众号解决大众需求，小程序开展疾病管理，形成公众号和小程序互相补充服务的模式，彻底实习诊前、预检、预约、支付，诊中报告查询、检查支付、支付，诊后复诊提醒、医患互动的全流程服务模式。 
// 3、中医馆小程序和应该形成互补，以为提供面上医疗服务，以小程序为提供点上专病专科服务的蓝图基本形成，将小程序的功能定位在为科室的专科专病服务，将小程序设计成健康管理应用，更好实现科室与的互通和互动，医患互动、实现医医互动、患患互动功能。 
// 4、中医馆小程序还可以实现健康管理的需求，互联网医疗最终的目的是通过各行业互联互通，实现以个人为中心或者以家庭为中心的健康管理模式，让每个人都有一个健康管理平台，记录个人的、运动、、等各类信息，再经过大数据的分析，给到个人健康管理的意见和建议，让每个人都具有合适自己的健康管理模式。"

// }
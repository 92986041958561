import React,{Component} from 'react'
import { Map ,Marker,InfoWindow} from 'react-amap'
// import Map from 'react-amap/lib/map';
// import Marker from 'react-amap/lib/marker';
// const AMap = window.AMap;
// import './index.css';
const mapKey = '1df858b24884ba196186b79f52009e38' //需要自己去高德官网上去申请
// const position = { longitude: 112.880269, latitude: 23.086433}//需要定位的经纬度112.880269,23.308643
const position = [114.351846,23.042868]//需要定位的经纬度114.35802,23.049466
class Address extends Component {
	constructor (props) {
		super (props)
		this.state = {
			searchContent:'',
		}
	}
  
	componentDidMount() {
		const _this = this
		let content = this.refs.container
		let map = new window.AMap.Map(content,{
			resizeEnable:true,
			zoom:15,
			center:position
		})
		var marker = new window.AMap.Marker({
			position:position//位置
		})
		map.add(marker);//添加到地图

	}
  
	render() {
		return (
			<div style={{width:"100%",height:400}} ref="container"></div>
		)
	}

			// <div style={{width: '100%', height: '400px'}}>
			// 	<Map amapkey={mapKey} zoom={15} center={position}>
			// 		<Marker position={position} ></Marker>
			// 	</Map>

			// </div>
			// <div style={{width: '100%', height: '400px'}}>
			// 	<div id="container" style={{ width:"300px", height:"200px" }}></div>
			// </div>
			// <div>
			// {/* //地图容器外层需要再多一层 */}
			// 	<div style={{width:650,height:255}} ref="container">
        	// 		  </div>
			// </div> 

}

export default Address